.control-label {
  font-size: 14px;
  font-weight: 600;
}

.month-year-select {
}

.editor-row {
  margin-top: 10px;
  padding: 5px;
}

.button-editor-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.danger-button {
  background-color: red;
  padding: 5px 15px 5px 15px;
  height: 40px;
  border: 8px solid transparent;
  color: #fff;
  font-weight: 800;
}

.danger-button:hover {
  background-color: transparent;
  height: 40px;
  border: 8px solid red;
  color: red;
  font-weight: 800;
}



.button-outline {
  background-color: transparent;
}


.secondary-button-white {
  background-color: #000;
  height: 35px;
  padding: 4px 14px 4px 14px;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 800;
}

.secondary-button-white:hover {
  background-color: #000;
  padding: 4px 14px 4px 14px;
  border: 1px solid #fff;
  color: #000;
  cursor: pointer;
}

.link-button {
  background-color: transparent;
  padding: 4px 14px 4px 14px;
  border: none;
  color: #000;
  font-family: 'Raleway';
  font-weight: bold;
}

.white-link-button {
  background-color: transparent;
  padding: 4px 14px 4px 14px;
  border: none;
  color: #fff;
}

.white-link-button:hover {
  background-color: transparent;
  padding: 4px 14px 4px 14px;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: #e3e3e3;
}

.link-button-lg {
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  padding: 0px;
}

.dark-mode {
  color: "#fff";
}

.button-lg {
  width: 250px;
  height: 40px;
}

.button-mega {
  height: 75px;
  font-size: 20px;
}



.danger {
  color: red;
}

.add-new-item-block {
  border: 1px solid #e3e3e3;
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.add-new-item-block:hover {
  cursor: pointer;
  border: 1px solid #000;
}

.section-option-item {
  min-width: 600px;
  position: relative;
  display: flex;
  flex-direction: row;
}

.section-option-item:hover {
  cursor: pointer;
}

.clickable:hover {
  cursor: pointer;
}


.remove-bottom-margin {
  margin-bottom: 0;
}

.new-profile-card {
  background-color: black;
  color: white;
  border: 1px solid black;
  font-weight: 800;
}

.new-profile-card:hover {
  background-color: white;
  color: black;
}

.skill-block-position-row {
  display: flex;
  flex-direction: row;
}

.skill-block-position-wrapper {
  flex: 1;
  margin-top: 0;
}

.feature-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media only screen and (max-width: 701px) {
  .skill-block-position-row {
    flex-direction: column;
  }

  .skill-block-position-wrapper {
    margin-top: 25px;
  }

  .feature-row {
    flex-direction: column;
  }
}

.hide {
  visibility: collapse;
}

.header-large {
  font-size: 28px;
}

#test-box {
  background-color: #2196f3 !important;
  color:red;
  min-height: 100px;
  min-width: 100px;
}

.primary-button:disabled {
  background-color: gray;
  pointer-events: none;
}

.skill-block-item-drop-zone {
  background-color: white;
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
}

.icon-button {
  max-width: 36px;
  max-height: 36px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button-hover {
  background-color: black;
  max-width: 36px;
  max-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button-svg {
  stroke: black;
}

.icon-button-svg-hover {
  stroke: red;
}

.icon-button-border {
  border: 2px solid #000;
}


.op_textfield_input {
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.editor_textfield_input {
  font-family: 'Raleway', sans-serif;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

@media only screen and (max-width: 800px) {
  .editor_textfield_input {
    font-family: 'Raleway', sans-serif;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: red;
  }
}


.op_textfield_input:hover {
  font-weight: 800;
}

.editor-text-button {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding:10px 10px 10px 0px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font: 'Raleway', sans-serif;
}


.editor-icon-button {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding:2px 2px 2px 2px;
  display: inline-block;
  justify-content: center;
  color: #202124;
  align-items: center;
  font-size: 14px;
  font: 'Raleway', sans-serif;
}

.editor-skill-tag-button {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding:10px 10px 10px 0px;
  display: flex;
  justify-content: flex-start;
  color: #202124;
  align-items: center;
  font-size: 14px;
  font: 'Raleway', sans-serif;
}

.editor-skill-tag-button-left-padding {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding:10px 10px 10px 10px;
  display: flex;
  justify-content: flex-start;
  color: #202124;
  align-items: center;
  font-size: 14px;
  font: 'Raleway', sans-serif;
}

.editor-skill-tag-button-left-padding:hover {
  border-color: #202124;
  padding-left:10px;
}

.editor-skill-tag-button:hover {
  border-color: #202124;
  padding-left:10px;
}

.editor-skill-tag-button-light:hover {
  border-color: #fff;
  padding-left: 10px;
}


.editor-text-button:hover {
  padding-left: 10px;
}

.editor-text-button-light:hover {
  border-color: #fff;
}


.editor-icon-button:hover {
  border-color: #202124;
}

.project-editor {
  margin-bottom: 30px;
  page-break-after: always !important;
}

.light-color {
  color: #fff;
}

.no-hover:hover {
  border-color: transparent;
  padding-left: 0px;
}

.align-center {
  text-align: center;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border:5px solid #202124;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
  border:5px solid #202124;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #202124;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}